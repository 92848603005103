import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('permission.roles')) + "： " + _toDisplayString(_ctx.roles), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.t('permission.switchRoles')) + "： ", 1),
    _createVNode(_component_el_radio_group, {
      modelValue: _ctx.currentRole,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRole) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio_button, { label: "editor" }),
        _createVNode(_component_el_radio_button, { label: "admin" })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}